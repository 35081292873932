import React from "react"
import DndLayout from "../components/dndlayout"
import styled, { createGlobalStyle } from "styled-components"
import { Link } from "gatsby"
import { Query } from "react-apollo"
import {NAME_QUERY, STATS_QUERY} from "../queries/queries"

const GlobalStyle = createGlobalStyle`
  body {
        background: #343940;
        color: #111;
	margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
       }
  html {
    box-sizing: border-box;
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
`

const Grid = styled.div`
  max-width: 930px;
  display: grid;
  grid-template-rows: auto 200px;
  height: 100vh;
  grid-column-gap: 12px;
  color: #aab8c4;
  margin: auto auto;
`
const Namebox = styled.div`
  height: 40px;
  background: #374049;
  border-radius: 15px;
  grid-column: span 4;
  max-width: 930px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  height: 100%;
  justify-content: space-between;
`
const StatsBox = styled.div`
  background: #374049;
  border-radius: 15px;
  grid-column: span 2;
`
const StatsBoxContent = styled.div`
  display:grid;
  grid-template-columns: 3fr 1fr 1fr;
  > h1 {
    grid-column: span 3;
  }
`
const Main = styled.main`
  display: grid;
  height: 100%;
  justify-content: center;
  grid: 60px repeat(4, 1fr) / repeat(4, 1fr);
  grid-gap: 12px;
`
const Footer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #343940;
  color: white;
  text-align: center;
`
const FooterContent = styled.div`
  max-width: 930px;
  margin: auto;
  display: flex;
  justify-content: space-around;
`
function bonus(stat) {
  return Math.floor((stat - 10) / 2)
}

const Page = ({ location }) => (
  <Grid>
    <GlobalStyle />
    <DndLayout>
      <Query query={STATS_QUERY}>
        {({ data, loading, error }) => {
          if (loading) return <p>Loading stats...</p>
          if (error) return <p>Error: ${error.message}</p>

          const {
            Charisma,
            Constitution,
            Dexterity,
            Intelligence,
            Strength,
            Wisdom,
          } = data.Stats[0]
          return (
            <>
              <StatsBox>
              <StatsBoxContent>
                <h1>Stats</h1>
                <h3>Stat</h3>
                <h3>Val </h3>
                <h3>Bonus</h3>
                <p>Charisma: </p>
                <p>{Charisma} </p>
                <p> {bonus(Charisma)}</p>
                <p>Strength: </p>
                <p>{Strength} </p>
                <p> {bonus(Strength)}</p>
                <p>Intelligence: </p>
                <p>{Intelligence} </p>
                <p> {bonus(Intelligence)}</p>
                <p>Dexterity: </p>
                <p>{Dexterity} </p>
                <p> {bonus(Dexterity)}</p>
                <p>Constitution: </p>
                <p>{Constitution} </p>
                <p> {bonus(Constitution)}</p>
                <p>Wisdom: </p>
                <p>{Wisdom} </p>
                <p> {bonus(Wisdom)}</p>
              </StatsBoxContent>
              </StatsBox>
            </>
          )
        }}
      </Query>
    </DndLayout>
  </Grid>
)

export default Page
